import { Box } from '@mui/material';
import React from 'react'
import HeaderTop from './HeaderTop';
// import SidebarAdm from './Sidebar';
import Header from '../../components/Shared/Header/Header'

const Layout = (Component) => ({ ...props }) => {

    return (
        <>
            <div style={{ display: 'flex', minHeight: "100vh" }}>
                {/* <SidebarAdm /> */}
                <Box sx={{ width: "100%", bgColor: "#fafafa" }}>
                    {/* <HeaderTop /> */}
                    <Header />
                    <Box sx={{ p: 3 }}>
                        <Component {...props} />
                    </Box>
                </Box>
            </div>
        </>
    )
}

export default Layout;