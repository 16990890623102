import React from 'react'
import TimedProgressBar from '../Progressbar/Progressbar'
import leader_img2 from "../../images/leader-2.jpg";

const Choose = () => {
  return (
    <div>
         <section>
        <div className="d-flex  flex-sm-column flex-md-row flex-lg-row flex-xs-column flex-column">
          {/* bg_color */}
          <div className="bg-black w-100">
            <div className=" px-2 pt-5 text-light w-100">
              <h3>Why customer chose us?</h3>
              <h2>
              We make in Bangladesh to source locally for a better country.
              </h2>
              <p className="pt-3 mx-1">
              AmarSource's R&D initiatives aim to alleviate capacity and capability development for academies and industries towards ‘4IR’.
              </p>
            </div>

            <div className="mx-4 text-light pt-5 ">
              <h6>One-stop engineering service provider</h6>
              <TimedProgressBar progress={85} />
              <h6>Global engineering connectivity</h6>
              <TimedProgressBar progress={35} />
              <h6>Team, Technology, Togetherness</h6>
              <TimedProgressBar progress={80} />
              <h6>6 years tested firmware</h6>
              <TimedProgressBar progress={70} />
            </div>
          </div>
          <div className="w-100">
            <img
              className="leader-img-style w-sm-100 w-md-10 w-100"
              src={leader_img2}
              alt="market leader img"
            />
          </div>
        </div>
      </section>
    </div>
  )
}

export default Choose