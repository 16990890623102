import Card from "@mui/material/Card";
import "./ProductCard.css";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import jsPDF from "jspdf";

import am from "../images/amar.jpeg";
import axiosInstance from "../pages/axiosInstance";
import { useEffect, useState } from "react";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import axiosInstance from "../pages/axiosInstance";

const ProductCard = ({
  isSelected, 
  onCheckboxChange,
  id,
  title,
  subheader,
  image,
  content,
  comments,
  likes,
  showProducts,
  likesId,
  feature1,
  feature2,
  feature3,
  feature4,
  feature5,
  feature6,
  feature7,
  feature8,
  feature9,
  feature10,
  techSpec1,
  techSpec2,
  techSpec3,
  techSpec4,
  techSpec5,
  techSpec6,
  techSpec7,
  techSpec8,
  techSpec9,
  techSpec10,
  techSpec11,
  techSpec12,
  onSelectProduct,
}) => {

  const toggleSelection = () => {
    onSelectProduct(id);
  };
  const [selectedProducts, setSelectedProducts] = useState([]);

  const { userInfo } = useSelector((state) => state.signIn);

  const [showFeatures, setShowFeatures] = useState(false);

  const toggleFeatures = () => {
    setShowFeatures(!showFeatures);
  };

  const addLike = async () => {
    try {
      const { data } = await axiosInstance.put(
        `${process.env.REACT_APP_API_URL}/api/addlike/product/${id}`
      );
      console.log("likes", data.product);
      if (data.success == true) {
        showProducts();
      }
    } catch (error) {
      console.log(error.response.data.error);
      toast.error(error.response.data.error);
    }
  };

  const removeLike = async () => {
    try {
      const { data } = await axiosInstance.put(
        `${process.env.REACT_APP_API_URL}/api/removelike/product/${id}`
      );
      console.log("remove likes", data.product);
      if (data.success == true) {
        showProducts();
      }
    } catch (error) {
      console.log(error.response.data.error);
      toast.error(error.response.data.error);
    }
  };

  


 const downloadProductAsPDF = () => {

    const doc = new jsPDF();

    // Set image size and position
    const imgWidth = 100;
    const imgHeight = 100;
    const imgMargin = 10;
    const imgX = (doc.internal.pageSize.width - imgWidth) / 2;
    let imgY = imgMargin;

    // Add image to the PDF
    doc.addImage(image, "JPEG", imgX, imgY, imgWidth, imgHeight);

    // Add product details
    let textY = imgY + imgHeight + imgMargin + 10;
    const textX = imgMargin;
    const maxWidth = doc.internal.pageSize.width - 2 * imgMargin;

    // Add product details to the PDF with padding
    if (title) {
      doc.setFont("helvetica", "bold");
      let titleLines = doc.splitTextToSize(`Product Title: ${title}`, maxWidth);
      doc.text(titleLines, textX, textY);
      doc.setFont("helvetica", "normal");
      textY = checkPageHeight(doc, textY, titleLines.length);
    }

    if (content) {
      doc.setFont("helvetica", "bold");
      let contentHeader = "Product Content:";
      let contentHeaderLines = doc.splitTextToSize(contentHeader, maxWidth);
      let contentLines = doc.splitTextToSize(content, maxWidth);
      doc.text(contentHeaderLines, textX, textY);
      doc.setFont("helvetica", "normal");
      textY = checkPageHeight(doc, textY, contentHeaderLines.length);
      doc.text(contentLines, textX, textY);
      textY = checkPageHeight(doc, textY, contentLines.length);
    }

    if (
      feature1 ||
      feature2 ||
      feature3 ||
      feature4 ||
      feature5 ||
      feature6 ||
      feature7 ||
      feature8 ||
      feature9 ||
      feature10
    ) {
      doc.setFont("helvetica", "bold");
      doc.text("Features:", textX, textY);
      doc.setFont("helvetica", "normal");
      textY += 10;
      let featureArray = [
        feature1,
        feature2,
        feature3,
        feature4,
        feature5,
        feature6,
        feature7,
        feature8,
        feature9,
        feature10,
      ];
      featureArray
        .filter((feature) => feature)
        .forEach((feature) => {
          let lines = doc.splitTextToSize(`- ${feature}`, maxWidth - 10);
          doc.text(lines, textX + 10, textY);
          textY = checkPageHeight(doc, textY, lines.length);
        });
      textY += 10;
    }

    if (
      techSpec1 ||
      techSpec2 ||
      techSpec3 ||
      techSpec4 ||
      techSpec5 ||
      techSpec6 ||
      techSpec7 ||
      techSpec8 ||
      techSpec9 ||
      techSpec10 ||
      techSpec11 ||
      techSpec12
    ) {
      doc.setFont("helvetica", "bold");
      doc.text("Technical Specifications:", textX, textY);
      doc.setFont("helvetica", "normal");
      textY += 10;
      let specArray = [
        techSpec1,
        techSpec2,
        techSpec3,
        techSpec4,
        techSpec5,
        techSpec6,
        techSpec7,
        techSpec8,
        techSpec9,
        techSpec10,
        techSpec11,
        techSpec12,
      ];
      specArray
        .filter((spec) => spec)
        .forEach((spec) => {
          let lines = doc.splitTextToSize(`- ${spec}`, maxWidth - 10);
          doc.text(lines, textX + 10, textY);
          textY = checkPageHeight(doc, textY, lines.length);
        });
    }

    // Save the PDF with a file name if any content is available
    if (
      title ||
      content ||
      feature1 ||
      feature2 ||
      feature3 ||
      feature4 ||
      feature5 ||
      feature6 ||
      feature7 ||
      feature8 ||
      feature9 ||
      feature10 ||
      techSpec1 ||
      techSpec2 ||
      techSpec3 ||
      techSpec4 ||
      techSpec5 ||
      techSpec6 ||
      techSpec7 ||
      techSpec8 ||
      techSpec9 ||
      techSpec10 ||
      techSpec11 ||
      techSpec12
    ) {
      doc.save("product_details.pdf");
    }

    
  };
  const checkPageHeight = (doc, y, lines) => {
    let pageHeight = doc.internal.pageSize.height;
    let lineHeight = 10; // Assuming font size is 10
    if (y + lines * lineHeight > pageHeight - 10) {
      doc.addPage();
      return 10; // Reset to top of the page
    }
    return y + lines * lineHeight;
  };

  // end pdf controller
  //pdf end





  return (
    <>
          <input type="checkbox" checked={isSelected} onChange={onCheckboxChange} />

    <div>
      <div  className="overflow-hidden">
        <div className="container">
          <div
            id="productInfo"
            className="d-flex flex-md-row flex-sm-column flex-column advisors-info border"
          >
            <div>
              <Link to={`/product/${id}`}>
                <img className="advisor-img" src={image} alt="advisor_team" />
              </Link>
            </div>

            <div className="ms-lg-1 mt-2">
             
              {
                <span
                  style={{
                    fontSize: "24px",
                    fontWeight: "bold",
                    color: "#333",
                  }}
                >
                  {title}
                </span>
              }

              <div
                className="ms-lg-2 me-4 ms-1 me-1"
                style={{ marginTop: "auto" }}
               >
                {content}

               
                <div>
                  {feature1 && <button onClick={toggleFeatures} className="mt-2 btn-link">
                    {showFeatures ? <FontAwesomeIcon icon={faMinus} /> : <FontAwesomeIcon icon={faPlus} />}
                  </button>}
                  {showFeatures && (
                    <div>
                      {feature1 && <h5>Features:</h5>}
                      <ul>
                        {feature1 && <li>{feature1}</li>}
                        {feature2 && <li>{feature2}</li>}

                        {feature3 && <li>{feature3}</li>}
                        {feature4 && <li>{feature4}</li>}
                        {feature5 && <li>{feature5}</li>}
                        {feature6 && <li>{feature6}</li>}
                        {feature7 && <li>{feature7}</li>}
                        {feature8 && <li>{feature8}</li>}
                        {feature9 && <li>{feature9}</li>}
                        {feature10 && <li>{feature10}</li>}
                      </ul>


                      
                    </div>

                    
                  )}
                  {showFeatures && (
                    <div>
                      {techSpec1 && <h5>Technical Specifications:</h5>}
                      <ul>
                        
                          {techSpec1 && <li>{techSpec1}</li>}
                          {techSpec2 && <li>{techSpec2}</li>}
                          {techSpec3 && <li>{techSpec3}</li>}
                          {techSpec4 && <li>{techSpec4}</li>}
                          {techSpec5 && <li>{techSpec5}</li>}
                          {techSpec6 && <li>{techSpec6}</li>}
                          {techSpec7 && <li>{techSpec7}</li>}
                          {techSpec8 && <li>{techSpec8}</li>}
                          {techSpec9 && <li>{techSpec9}</li>}
                          {techSpec10 && <li>{techSpec10}</li>}
                          {techSpec11 && <li>{techSpec11}</li>}
                          {techSpec12 && <li>{techSpec12}</li>}
                       
                      </ul>
                    </div>
                  )}

            
                </div>

                <div className="mt-lg-5 d-flex flex-row mb-1 align-items-end">
                  {/* <Link className="me-2" to={`/product/${id}`}>
                    <button className="mt-5 px-5 py-sm-4 py-md-2 py-lg-2 py-1 feature-btn">
                      More Features
                    </button>
                  </Link> */}
                  <Link to="/buycontact" className="ms-3">
                    <button className="mt-5 px-5 py-sm-4 py-md-2 py-lg-2 py-1 feature-btn">
                      Buy Product
                    </button>
                  </Link>

                  {/* Download button */}
                <button
                   className="mt-5 px-5 mx-2 py-sm-4 py-md-2 py-lg-2 py-1 feature-btn"
                  onClick={downloadProductAsPDF}
                >
                  Download
                </button>
                
                </div>
                
              </div>
            </div>
          </div>
          {/* <input type="checkbox" checked={isSelected} onChange={toggleSelection} /> */}
        </div>
        
      </div>
      

    </div>
    </>
  );
};

export default ProductCard;
