import { useEffect, useState } from "react";
import { Box, Container, Grid } from "@mui/material";
import moment from "moment";
import Loader from "../components/Loader";
import { io } from "socket.io-client";
import axiosInstance from "./axiosInstance";
import HomeFooter from "../components/Shared/Footer/HomeFooter";
import Header from "../components/Shared/Header/Header";
import MemberCard from "../components/MemberCard";
import "../components/Team/Team.css";

// import ceo from "../masum_ceo.png";
import ceo from "../images/team/coreteam/masum_ceo.png";
import cto from "../images/team/coreteam/hasanur_cto.png";
import hr from "../images/team/coreteam/tilottoma-hr.jpg";

const socket = io("/", {
  reconnection: true,
});

const TeamMembers = () => {
  const [posts, setPosts] = useState([]);
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [postAddLike, setPostAddLike] = useState([]);
  const [postRemoveLike, setPostRemoveLike] = useState([]);

  const showMembers = async () => {
    setLoading(true);
    try {
      //
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}/api/members/show`
      );
      setMembers(data.members);
      setLoading(false);
    } catch (error) {}
  };

  useEffect(() => {
    showMembers();
  }, []);

  useEffect(() => {
    socket.on("add-like", (newPosts) => {
      setPostAddLike(newPosts);
      setPostRemoveLike("");
    });
    socket.on("remove-like", (newPosts) => {
      setPostRemoveLike(newPosts);
      setPostAddLike("");
    });
  }, []);

  let uiPosts =
    postAddLike.length > 0
      ? postAddLike
      : postRemoveLike.length > 0
      ? postRemoveLike
      : members;

  return (
    <>
      <Header />
      <div className="text-center mt-5">
        <h2>
          <span className="teamCoverText custom-font">
            <i>Our Team</i>
          </span>
        </h2>
        <p className="pt-4 fs-5 mx-1 fw-bold mx-sm-5 ">
          <i>
            Meet our diverse team of world-class creators, designers and problem
            solvers.
          </i>
        </p>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-12 col-12 mb-2">
            <div className="card  border border-2 h-100">
              <div className="card-body ">
                <img className="otherTeamMembersImg" src={ceo} alt="" />
                <div className="teamInfo">
                  <h5>M H M Mazadur Rahman</h5>
                  <p>Managing Director</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 col-12 mb-2">
            <div className="card h-100">
              <div className="card-body ">
                <img
                  className="otherTeamMembersImg"
                  src={cto}
                  alt=""
                />
                <div className="teamInfo">
                  <h5>SM Hasanur Rashid</h5>
                  <p>Chief Technology Officer</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 col-12 mb-2">
            <div className="card h-100">
              <div className="card-body ">
                <img className="otherTeamMembersImg" src={hr} alt="" />
                <div className="teamInfo">
                  <h5>Tilottoma Saha</h5>
                  <p>HRM & Communication in charge</p>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>

      <Box sx={{ bgColor: "#fafafa"}}>
      {/* , minHeight: "100vh"  */}
        <Container sx={{  pb: 5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              {loading ? (
                <Loader />
              ) : (
                uiPosts.map((member, index) => (
                  <Grid
                    item
                    xs={12}
                    sm={3}
                    md={3}
                    lg={3}
                    key={index}
                    className="overflow-hidden"
                  >
                    <MemberCard
                      id={member._id}
                      name={member.name}
                      designation={member.designation}
                      image={member.image ? member.image.url : ""}
                      subheader={moment(member.createdAt).format(
                        "MMMM DD, YYYY"
                      )}
                      comments={member.comments.length}
                      likes={member.likes.length}
                      likesId={member.likes}
                      showMembers={showMembers}
                    />
                  </Grid>
                ))
              )}
            </Grid>
          </Box>
        </Container>
        <HomeFooter />
      </Box>
    </>
  );
};

export default TeamMembers;
