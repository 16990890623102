import React from "react";
import "./Address.css";
import Lottie from "lottie-react";
import LottieRobot from "../../animation.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faLocationDot,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
const Address = () => {
  return (
    <div>
      <div className="d-flex flex-lg-row flex-md-column flex-sm-column flex-column mx-lg-5 py-3 ">
        <div className="embed-responsive embed-responsive-16by9 ">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d29213.337200224978!2d90.3556467279536!3d23.759246084489064!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755b9164328ba2f%3A0x1b605f085dacf770!2sAmarSource%20Ltd.!5e0!3m2!1sen!2sbd!4v1720297252422!5m2!1sen!2sbd"
            width="600"
            height="450"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <div className="d-flex  flex-column text-left  ms-lg-1 me-lg-1 p-lg-5  bg-location w-100">
          <div className="d-flex">
            <div>
              <FontAwesomeIcon className="pe-3 fs-3 " icon={faLocationDot} />
            </div>
            <div>
              <h3>Office Location</h3>
              <address>
               <i>
               House#325, Ashirbad, Road#3, <br />
               Adabor, Dhaka - 1207, Bangladesh
               </i>

                <br />
              </address>
            </div>
          </div>

          <div className="d-flex mb-3">
            <div>
              <div>
                <FontAwesomeIcon className="pe-2 fs-3 " icon={faEnvelope} />
              </div>
            </div>
            <div><i><a className="text-decoration-none text-black" href="contact@amarsource.com"><u>contact@amarsource.com </u></a> </i></div>
          </div>
          <div className="d-flex">
            <div>
              <div>
                <FontAwesomeIcon className="pe-2 fs-3 " icon={faPhone} />
              </div>
            </div>
            <div>+880 1816-688711</div>
          </div>
        </div>
      </div>

      <div></div>
    </div>
  );
};

export default Address;
