
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./Footer.css";
import { faFacebookF, faInstagram, faLinkedin, faTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons";

const HomeFooter = () => {
  return (
    <div>
      <div className="bgColor pt-5 ">
        <div className="container  pt-5">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-12 col-12 text-start text-white pt-4">
              <h2>AmarSource Ltd.</h2>
              <p>
              SOURCE LOCALLY FOR BETTER BANGLADESH.
              </p>
              <button className="social-info"><a target="_blank" href="https://www.facebook.com/amarsource"><FontAwesomeIcon className="icon-sizing" icon={faFacebookF} /></a></button>
              <button className="social-info"><a target="_blank" href=""><FontAwesomeIcon className="icon-sizing" icon={faInstagram} /></a></button>
              <button className="social-info"><a target="_blank" href="https://www.youtube.com/@amarsource"><FontAwesomeIcon className="icon-sizing" icon={faYoutube} /></a></button>

              <button className="social-info"><a target="_blank" href=""><FontAwesomeIcon className="icon-sizing" icon={faTwitter} /></a></button>

              <button className="social-info"><a target="_blank" href="https://www.linkedin.com/company/amarsource/"><FontAwesomeIcon className="icon-sizing" icon={faLinkedin} /></a></button>

              
            </div>

            <div className="col-lg-4 col-md-4 col-sm-12 col-12 row  text-white pt-4">
              <h3>SERVICES</h3>
              <a className="text-white text-decoration-none hover-effect"  href="#">
                <li>Engineering Research and Product Development</li>
              </a>
              <a className="text-white text-decoration-none" href="#">
                <li>Precision Agriculture</li>
              </a>
              <a className="text-white text-decoration-none hover-effect" href="#">
                <li>Engineering Design and Fabrication Services</li>
              </a>
              <a className="text-white text-decoration-none hover-effect" href="#">
                <li>Engineering Service</li>
              </a>
              <a className="text-white text-decoration-none hover-effect" href="#">
                <li>Maintanance and Repair</li>
              </a>
              
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 col-12 row pt-4 text-white  text-left">
              <h2>CONTACT US</h2>
              <address>
              House#325, Ashirbad, Road#3,
                <br /> Adabor, Dhaka - 1207, Bangladesh <br />
                Email: <a href="mailto:contact@amarsource.com" className="text-white">contact@amarsource.com</a>
                <br />
                Phone: +880 1816-688711
              </address>
            </div>
          </div>

          
        </div>


        <hr className="border border-light border-1 opacity-75" />

          <div className="d-flex gap-3 flex-lg-row flex-md-row flex-sm-column flex-column container">
            <div className="text-white me-5">
              <small>
                &copy;2024-2025 All Rights Reserved | Amarsource LTD.
              </small>
            </div>

            <div className=" d-flex gap-3 flex-lg-row flex-md-row flex-sm-column flex-column text-start">
              <div className="ms-lg-5">
                <p>
                  <a target="_blank" className="text-white text-decoration-none " href="https://www.facebook.com/greensofttech">
                  Developed by: <b className="hover-effect border-bottom border-warning">Green Software Technology - GST</b>
                    
                  </a>
                </p>
              </div>

              <div>
                <p>
                <a className="text-white text-decoration-none pe-2 " href="#">
                Privacy Policy
                  </a>
                  <a className="text-white text-decoration-none " href="#">
                    Terms & Conditions
                  </a>
                </p>
              </div>

              <div>
                <p className="">
                  <a className="text-white text-decoration-none " href="#">
                    FAQ
                  </a>
                </p>
              </div>
            </div>
          </div>
      </div>


      
    </div>

  );
};

export default HomeFooter;
