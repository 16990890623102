import React from 'react';
import './Partner2.css'; // Import the CSS file for styling

const Partner2 = ({ logos }) => {
  return (
    <div className="carousel-container">
      <div className="logo-carousel">
        {logos.map((logo, index) => (
          <img key={index} src={logo} className='logo-partners' alt={`Logo ${index + 1}`} />
        ))}
      </div>
    </div>
  );
};

export default Partner2;
