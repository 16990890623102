
import React from "react";
import './ClientsFeedback.css'
import img1 from "../../images/client1.png"
import img2 from "../../images/client2.png";
import img3 from "../../images/client3.png";
import img4 from "../../images/client4.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

const ClientsFeedback = () => {
  return (
    <>
     <div className="pb-2">
     <h3 className="text-center mt-5  pt-5 fontColor "><span className="border-custom">Feedback from clients</span></h3>
      
     </div>
    <div
      id="carouselExampleSlidesOnly"
      class="carousel slide "
      data-bs-ride="carousel"
      data-interval="2000"
    >
      <div class="carousel-inner">
        <div class="carousel-item active ">
          <div className="row mx-lg-4">
            <div className="col-sm-6 mb-3 mb-sm-0">
              <div className="card">
                <div className="card-body my-5 px-5">
                  <div className="d-flex flex-sm-column flex-md-row flex-lg-row flex-xs-column flex-column">
                    <div>
                      <img className="carousel-img" src={img1} alt="card-img" />
                    </div>
                    <div className="ps-3 pt-3">
                      <h2>Naderilahi</h2>
                      <h6>Germany</h6>
                    </div>
                  </div>

                  <p className="card-text pt-3">
                  Amarsource did an excellent job on my 3D design concept. They quickly understood my vision, delivered high-quality work, and were very responsive to feedback. The final result exceeded my expectations, and I highly recommend them for any 3D design needs.
                  </p>
                  <FontAwesomeIcon className="icon-style" icon={faStar} />
                  <FontAwesomeIcon className="icon-style" icon={faStar} />
                  <FontAwesomeIcon className="icon-style" icon={faStar} />
                  <FontAwesomeIcon className="icon-style" icon={faStar} />
                  <FontAwesomeIcon className="icon-style" icon={faStar} />
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="card">
                <div className="card-body my-5 px-5">
                  <div className="d-flex flex-sm-column flex-md-row flex-lg-row flex-xs-column flex-column">
                    <div>
                      <img className="carousel-img" src={img2} alt="card-img" />
                    </div>
                    <div className="ps-3 pt-3">
                      <h2>Ajbarreto</h2>
                      <h6>United States</h6>
                    </div>
                  </div>

                  <p className="card-text pt-3">
                  They did a good job designing towards my vision. They're Communication to see how everything would fit was great. and they had a great attention to details to make sure everything would fit.
                  </p>
                  <FontAwesomeIcon className="icon-style" icon={faStar} />
                  <FontAwesomeIcon className="icon-style" icon={faStar} />
                  <FontAwesomeIcon className="icon-style" icon={faStar} />
                  <FontAwesomeIcon className="icon-style" icon={faStar} />
                  <FontAwesomeIcon className="icon-style" icon={faStar} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-item active">
          <div className="row mx-lg-4">
            <div className="col-sm-6 mb-3 mb-sm-0">
              <div className="card">
                <div className="card-body my-5 px-5">
                  <div className="d-flex flex-sm-column flex-md-row flex-lg-row flex-xs-column flex-column">
                    <div>
                      <img className="carousel-img" src={img3} alt="card-img" />
                    </div>
                    <div className="ps-3 pt-3">
                      <h2>
                      Nihart</h2>
                      <h6>India</h6>
                    </div>
                  </div>

                  <p className="card-text pt-3">
                  Working with Amarsource Ltd has been a fantastic experience! Their attention to detail and professionalism exceeded my expectations. They were incredibly responsive and cooperative, delivering high-quality work on time. Highly recommend for any 3D design needs—you won't be disappointed! 👍.</p>
                  <FontAwesomeIcon className="icon-style" icon={faStar} />
                  <FontAwesomeIcon className="icon-style" icon={faStar} />
                  <FontAwesomeIcon className="icon-style" icon={faStar} />
                  <FontAwesomeIcon className="icon-style" icon={faStar} />
                  <FontAwesomeIcon className="icon-style" icon={faStar} />
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="card">
                <div className="card-body my-5 px-5">
                  <div className="d-flex flex-sm-column flex-md-row flex-lg-row flex-xs-column flex-column">
                    <div>
                      <img className="carousel-img" src={img4} alt="card-img" />
                    </div>
                    <div className="ps-3 pt-3">
                      <h2>Monstatek</h2>
                      <h6>South Korea</h6>
                    </div>
                  </div>

                  <p className="card-text pt-3">
                  Fantastic experience. One of , if not the best sevice, on FIverr. Would not hesitate to work with Amarsource again. Highly recommended.
                  </p>
                  <FontAwesomeIcon className="icon-style" icon={faStar} />
                  <FontAwesomeIcon className="icon-style" icon={faStar} />
                  <FontAwesomeIcon className="icon-style" icon={faStar} />
                  <FontAwesomeIcon className="icon-style" icon={faStar} />
                  <FontAwesomeIcon className="icon-style" icon={faStar} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default ClientsFeedback;
