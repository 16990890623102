import { Box } from '@mui/material'
import React from 'react'

const Footer = () => {
    return (
        <>
            <Box sx={{ bgcolor: "rgb(50,38,80)", height: "60px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Box sx={{ color: "#fafafa" }}>Footer</Box>
            </Box>
            
        </>
    )
}

export default Footer