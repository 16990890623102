
import React, { useState, useEffect } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import './Progressbar.css';
function TimedProgressBar({ progress }) {
  const [currentProgress, setCurrentProgress] = useState(0);
  const duration = 5000; // 5000 milliseconds = 5 seconds

  useEffect(() => {
    let interval;
    let currentTime = 0;
    const step = 100;

    const updateProgress = () => {
      currentTime += step;
      const newProgress = (currentTime / duration) * progress;
      setCurrentProgress(newProgress >= progress ? progress : newProgress);
      if (currentTime >= duration) {
        clearInterval(interval);
      }
    };

    interval = setInterval(updateProgress, step);

    return () => clearInterval(interval);
  }, [progress]);

  return (
    <ProgressBar   
    now={currentProgress} 
    
    className='mb-4 fontColor custom-progress-bar'
    label={`${Math.round(currentProgress)}%`} />
  );
}

export default TimedProgressBar;
