import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';

function NotFound() {
  return (
    <Container className="vh-100 d-flex flex-column justify-content-center align-items-center">
      <Row>
        <Col xs={12} className="text-center">
          <h1 className="error-text custom-font">Oops!</h1>
          <p className="error-message">
            The page you are looking for might have been removed, had its name changed or is temporarily unavailable.
          </p>
          <Button className='btn-style fw-bold' href="/">
            Go to Homepage
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default NotFound;