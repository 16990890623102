import React from 'react';
import Partner2 from './Partners2';
import img1 from "../../images/govt.jpg";
import img2 from "../../images/spectrum.png";
import img3 from "../../images/a2i.jpg";
import img4 from "../../images/Buet.png";
import img5 from "../../images/Dhaka_University_logo.svg";
import img6 from "../../images/NFDDP.png";
import img7 from "../../images/AUGMEDIX.jpeg";
import img8 from "../../images/Bangladesh_Agricultural_University_(crest).png";
import img9 from "../../images/bangladesh agricultural research institute logo.png";
import img10 from "../../images/CRP.jpeg";
import img11 from "../../images/Practical Action.png";
import img12 from "../../images/WaterAid.png";
import img13 from "../../images/Department of environment.png";
import img14 from "../../images/UIU.png";
import img15 from "../../images/Surge.png";
import img16 from "../../images/essity.png";
import img17 from "../../images/UITS.png";
import img18 from "../../images/amor.png";
import img19 from "../../images/YSI.png";
import img20 from "../../images/LGED.png"
import img21 from "../../images/Bangladesh Atomic Energy Comission.png";
import img22 from "../../images/Mojadar.png"
import img23 from "../../images/Aarong dairy.png"
import img24 from "../../images/DSM.png"
import img25 from "../../images/Edifice.png"
import img26 from "../../images/Army.png"
import img27 from "../../images/Fiverr.png"
import img28 from "../../images/Reve system.png"
import img29 from "../../images/Artisan Craft.png"
import img30 from "../../images/Vuter bari.png"
import img31 from "../../images/AUGMEDIX.jpeg"
const logos = [img1,img2,img3,img4,img5,img6,img7,img8,img9,img10,img11,img12,img13,img14,img15,img16,img17,img18,img19,img20,img21,img22,img23,img24,img25,img26,img27,img28,img29,img30,img31]; // Add more logo URLs as needed


const Partners = () => {
  return (
    <div>
      <h2 className="text-center fontColor pt-5 pb-2">PARTNERS & CLIENTS</h2>
      <Partner2 logos={logos} />
    </div>
  );
};

export default Partners;
