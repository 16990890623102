import React, { useEffect, useState } from "react";
import { Box, Container, Grid } from "@mui/material";
import moment from "moment";
import Loader from "../components/Loader";
import { io } from "socket.io-client";
import ProductCard from "../components/ProductCard";
import "./Pro.css";
import axiosInstance from "./axiosInstance";
import Header from "../components/Shared/Header/Header";
import HomeFooter from "../components/Shared/Footer/HomeFooter";
import jsPDF from "jspdf";

const socket = io("/", {
  reconnection: true,
});

const BlogPro = () => {
  const [selectedProducts, setSelectedProducts] = useState([]);

  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [postAddLike, setPostAddLike] = useState([]);
  const [postRemoveLike, setPostRemoveLike] = useState([]);

  

const downloadSelectedProducts = async (selectedProductIds) => {
  const pdf = new jsPDF();

  try {
    // Fetch all product data concurrently
    const productPromises = selectedProductIds.map(async (productId) => {
      const { data } = await axiosInstance.get(
        `/api/product/${productId}`
      );
      return data.product;
    });

    const productsData = await Promise.all(productPromises);

    for (let i = 0; i < productsData.length; i++) {
      // Skip adding a new page for the first product
      if (i > 0) {
        pdf.addPage();
      }

      const product = productsData[i];
      let yPos = 10; // Reset Y position for the new page

      // Load image data as a data URL
      const imageData = await fetch(product.image.url)
        .then((response) => response.blob())
        .then((blob) => URL.createObjectURL(blob));

      // Add image to the PDF
      const imageWidth = 80;
      const imageHeight = 80;
      pdf.addImage(imageData, "JPEG", 10, yPos, imageWidth, imageHeight);
      yPos += imageHeight + 10;

      // Add other product information to the PDF
      yPos += 10;
      const contentLines = pdf.splitTextToSize(
        `Content: ${product.content}`,
        pdf.internal.pageSize.width - 20
      );
      const contentHeight = contentLines.length * 10;

      // Add Features to the PDF
      const featuresList = [];
      for (let j = 1; j <= 10; j++) {
        const featureKey = `feature${j}`;
        if (product[featureKey]) {
          featuresList.push(product[featureKey]);
        }
      }

      const featuresHeight = featuresList.length * 10;

      // Add Technical Specifications to the PDF
      const techSpecArray = [
        product.techSpec1,
        product.techSpec2,
        product.techSpec3,
        product.techSpec4,
        product.techSpec5,
        product.techSpec6,
        product.techSpec7,
        product.techSpec8,
        product.techSpec9,
        product.techSpec10,
        product.techSpec11,
        product.techSpec12,
      ].filter(Boolean);

      const techSpecHeight = techSpecArray.length * 10;

      pdf.text(contentLines, 10, yPos);
      yPos += contentHeight + 10;

      // Check if there is enough space for features
      if (featuresList.length > 0 && yPos + featuresHeight > pdf.internal.pageSize.height) {
        pdf.addPage();
        yPos = 10; // Reset yPos for the new page
      }

      if (featuresList.length > 0) {
        pdf.text("Features:", 10, yPos);
        yPos += 5;

        const featuresLines = pdf.splitTextToSize(
          featuresList.map((feature) => `- ${feature}`).join("\n"),
          pdf.internal.pageSize.width - 20
        );

        pdf.text(featuresLines, 10, yPos);
        yPos += featuresHeight + 10;
      }

      // Check if there is enough space for tech specs
      if (techSpecArray.length > 0 && yPos + techSpecHeight > pdf.internal.pageSize.height) {
        pdf.addPage();
        yPos = 10; // Reset yPos for the new page
      }

      if (techSpecArray.length > 0) {
        pdf.text("Technical Specifications:", 10, yPos);
        yPos += 5;

        const techSpecLines = pdf.splitTextToSize(
          techSpecArray.map((techSpec) => `- ${techSpec}`).join("\n"),
          pdf.internal.pageSize.width - 20
        );

        pdf.text(techSpecLines, 10, yPos);
        yPos += techSpecHeight + 10;
      }
    }

    // Save the PDF
    pdf.save("selected_products.pdf");
  } catch (error) {
    console.error("Error fetching product data:", error);
  }
};

  

  const handleCheckboxChange = (productId) => {
    if (selectedProducts.includes(productId)) {
      setSelectedProducts(selectedProducts.filter((id) => id !== productId));
    } else {
      setSelectedProducts([...selectedProducts, productId]);
    }
  };

  const handleDownloadSelected = () => {
    downloadSelectedProducts(selectedProducts);
  };

  const showProducts = async () => {
    setLoading(true);
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}/api/products/show`
      );
      setProducts(data.products);
      setLoading(false);
    } catch (error) {}
  };

  useEffect(() => {
    showProducts();
  }, []);

  useEffect(() => {
    socket.on("add-like", (newPosts) => {
      setPostAddLike(newPosts);
      setPostRemoveLike("");
    });
    socket.on("remove-like", (newPosts) => {
      setPostRemoveLike(newPosts);
      setPostAddLike("");
    });
  }, []);

  let uiPosts =
    postAddLike.length > 0
      ? postAddLike
      : postRemoveLike.length > 0
      ? postRemoveLike
      : products;

  return (
    <>
      <Header />
      <Box sx={{ bgColor: "#fafafa", minHeight: "100vh" }}>
        <div className="text-center my-5  ">
          <h1>
            <span className="product-header ">Our Products</span>
          </h1>
        </div>
        <Container sx={{ pt: 5, pb: 5, minHeight: "83vh" }}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={{ xs: 2, md: 3 }}>
              {loading ? (
                <Loader />
              ) : (
                uiPosts.map((product, index) => (
                  <Grid item xs={12} sm={12} md={12} key={index}>
                    <ProductCard
                      image={product.image ? product.image.url : ""}
                      id={product._id}
                      title={product.title}
                      content={product.content}
                      feature1={product.feature1}
                      feature2={product.feature2}
                      feature3={product.feature3}
                      feature4={product.feature4}
                      feature5={product.feature5}
                      feature6={product.feature6}
                      feature7={product.feature7}
                      feature8={product.feature8}
                      feature9={product.feature9}
                      feature10={product.feature10}
                      techSpec1={product.techSpec1}
                      techSpec2={product.techSpec2}
                      techSpec3={product.techSpec3}
                      techSpec4={product.techSpec4}
                      techSpec5={product.techSpec5}
                      techSpec6={product.techSpec6}
                      techSpec7={product.techSpec7}
                      techSpec8={product.techSpec8}
                      techSpec9={product.techSpec9}
                      techSpec10={product.techSpec10}
                      techSpec11={product.techSpec11}
                      techSpec12={product.techSpec12}
                      subheader={moment(product.createdAt).format(
                        "MMMM DD, YYYY"
                      )}
                      comments={product.comments.length}
                      likes={product.likes.length}
                      likesId={product.likes}
                      showProducts={showProducts}
                      isSelected={selectedProducts.includes(product._id)}
                      onCheckboxChange={() => handleCheckboxChange(product._id)}
                    />
                  </Grid>
                ))
              )}
            </Grid>
          </Box>
        </Container>
        <button onClick={handleDownloadSelected}>
          Download Selected Products
        </button>
      </Box>
      <HomeFooter />
    </>
  );
};
export default BlogPro;
