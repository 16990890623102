
export const USER_SIGNIN_REQUEST = "USER_SIGNIN_REQUEST";
export const USER_SIGNIN_SUCCESS = "USER_SIGNIN_SUCCESS";
export const USER_SIGNIN_FAIL = "USER_SIGNIN_FAIL";
export const USER_SIGNIN_RESET = "USER_SIGNIN_RESET";

export const USER_SIGNUP_REQUEST = "USER_SIGNUP_REQUEST";
export const USER_SIGNUP_SUCCESS = "USER_SIGNUP_SUCCESS";
export const USER_SIGNUP_FAIL = "USER_SIGNUP_FAIL";
export const USER_SIGNUP_RESET = "USER_SIGNUP_RESET";

export const USER_LOGOUT_REQUEST = "USER_LOGOUT_REQUEST";
export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";
export const USER_LOGOUT_FAIL = "USER_LOGOUT_FAIL";
export const USER_LOGOUT_RESET = "USER_LOGOUT_RESET";


export const USER_LOAD_REQUEST = "USER_LOAD_REQUEST";
export const USER_LOAD_SUCCESS = "USER_LOAD_SUCCESS";
export const USER_LOAD_FAIL = "USER_LOAD_FAIL";
export const USER_LOAD_RESET = "USER_LOAD_RESET";


// export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN';





