import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import CommentIcon from '@mui/icons-material/Comment';
import ShareIcon from '@mui/icons-material/Share';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import am from '../images/amar.jpeg'
import axiosInstance from '../pages/axiosInstance';




const MemberCard = ({
    id,
    name,
    subheader,
    image,
    designation,
    comments,
    likes,
    showMembers,
    likesId
}) => {


    const { userInfo } = useSelector(state => state.signIn);

    //add like
    const addLike = async () => {
        try {
            // was empty
            const { data } = await axiosInstance.put(`${process.env.REACT_APP_API_URL}/api/addlike/post/${id}`);
            console.log("likes", data.post);
            if (data.success == true) {
                showMembers();
            }
        } catch (error) {
            console.log(error.response.data.error);
            toast.error(error.response.data.error)
        }
    }



    //remove like
    const removeLike = async () => {
        try {
            // was empty
            const { data } = await axiosInstance.put(`${process.env.REACT_APP_API_URL}/api/removelike/post/${id}`);
            console.log("remove likes", data.post);
            if (data.success == true) {
                showMembers();
            }
        } catch (error) {
            console.log(error.response.data.error);
            toast.error(error.response.data.error)
        }
    }


    return (
        <>
        
        <Card sx={{ maxWidth: 340 }} className='border p-2'>
            
            <Link>

                <CardMedia
                    component="img"
                    width="250"
                    height="280"
                    image={image}
                    alt="Paella dish"
                />
            </Link>
            {/* <CardContent> */}
            <div className="teamInfo ">
                  <h5>{name}</h5>
                  <p>{designation}</p>
                </div>
                
            {/* </CardContent> */}

        </Card>
        </>
    );
}

export default MemberCard;