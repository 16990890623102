import React, { useEffect, useState } from 'react'
import './ScrollButton.css';
import { faAnglesUp, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const ScrollButton = () => {
    
    const [showScroll, setShowScroll] = useState(false);

    const checkScrollTop = () => {
      if (!showScroll && window.pageYOffset > 400) {
        setShowScroll(true);
      } else if (showScroll && window.pageYOffset <= 400) {
        setShowScroll(false);
      }
    };
  
    useEffect(() => {
      window.addEventListener('scroll', checkScrollTop);
      return () => {
        window.removeEventListener('scroll', checkScrollTop);
      };
    }, [showScroll]);
  
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    };
  
    return (
      <div className="scroll-button">
        {showScroll && (
          <button onClick={scrollToTop} className='button-container'>
            <span>
            <FontAwesomeIcon icon={faAnglesUp}  className='moving-button'/> <br />
            <small>Top</small>
            </span>
          </button>
        )}
      </div>
    );
}

export default ScrollButton