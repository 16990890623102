import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import Header from "../Shared/Header/Header";
import HomeFooter from "../Shared/Footer/HomeFooter";
import contactImg from "../../images/contact.jpg";
import "./Contact.css";
import {
  faEnvelope,
  faLocationDot,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [texts, setTexts] = useState("");

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    setName("");
    setEmail("");
    setPhone("");
    setSubject("");
    setTexts("");

    emailjs
      .sendForm(
        "service_nb5cewq",
        "template_6dvq0sa",
        form.current,
        "s2JMah9F8HqJXd99w"
      )
      .then(
        (result) => {
          toast.success("Send Success!");
          console.log(result.text);
          console.log("message sent");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div className="overflow-hidden bg-contact">
      <Header />

      <div className="container   overflow-hidden">
        <h1 className="text-center my-5 ">
          <span className=" contact-font">CONTACT US</span>
        </h1>

        <div className="d-flex flex-lg-row flex-md-row flex-sm-column flex-xs-column flex-column contact-container">
          <div className="contact-card1">
            <FontAwesomeIcon className="contact-icons" icon={faEnvelope} />
            <h5 className="fw-bold">Mail Here</h5>
            <p>
              <a
                className="text-decoration-none text-black"
                href="mailto:contact@amarsource.com"
              >
                contact@amarsource.com
              </a>
            </p>
          </div>

          <div className="contact-card1">
            <FontAwesomeIcon className="contact-icons" icon={faLocationDot} />
            <h5 className="fw-bold">Visit Here</h5>
            <address>
              <i>
                House#325, Ashirbad, Road#3, <br />
                Adabor, Dhaka - 1207, Bangladesh
              </i>
            </address>
          </div>

          <div className="contact-card1">
            <FontAwesomeIcon className="contact-icons" icon={faPhone} />
            <h5 className="fw-bold">Call Here</h5>
            <p>+880 1816-688711</p>
          </div>
        </div>

        <h1 className="text-center mt-5">Get In Touch With Us</h1>
        <p className="text-center">
          Anything On Your Mind. We'll Be Glad To Assist you!
        </p>

        {/* contact form */}
        <div
          id="contactForm"
          className="d-flex flex-lg-row flex-md-column flex-sm-column flex-xs-column flex-column gap-5"
        >
          <div className="w-100 me-1">
            <img className="contact-img" src={contactImg} alt="contact Img" />
          </div>
          <div className="w-100">
            <form className="mb-3" ref={form} onSubmit={sendEmail}>
              <input
                type="text"
                className="w-100 mt-3 border"
                placeholder="Your Name"
                name="user_name"
                onChange={(event) => setName(event.target.value)}
                value={name}
                style={{ backgroundColor: "white", fontSize: "20px" }}
                required
              />
              <br />
              <input
                type="email"
                className="w-100 mt-3 border"
                placeholder="Your Email Address"
                name="user_email"
                onChange={(event) => setEmail(event.target.value)}
                value={email}
                style={{ backgroundColor: "white", fontSize: "20px" }}
                required
              />
              <br />
              <input
                type="tel"
                className="form-width mt-3 border"
                placeholder="Your Phone Number"
                name="user_phone"
                onChange={(event) => setPhone(event.target.value)}
                value={phone}
                style={{ backgroundColor: "white", fontSize: "20px" }}
                required
              />
              <input
                type="tel"
                className=" mt-3 form-width border"
                placeholder="Your Subject"
                name="user_subject"
                onChange={(event) => setSubject(event.target.value)}
                value={subject}
                style={{ backgroundColor: "white", fontSize: "20px" }}
                required
              />

              <textarea
                className="mt-3"
                rows="5"
                cols="35"
                name="message"
                placeholder="Enter message"
                onChange={(event) => setTexts(event.target.value)}
                value={texts}
                style={{ backgroundColor: "white", fontSize: "20px" }}
                required
              ></textarea>
              <br />
              <input type="submit" value="Send" className=" contact-btn" />
            </form>
          </div>
        </div>

        <div>
          <h2 className="text-center mt-5"><u>Our Location</u></h2>
        <div className="my-5 map-responsive">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d29213.337200224978!2d90.3556467279536!3d23.759246084489064!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755b9164328ba2f%3A0x1b605f085dacf770!2sAmarSource%20Ltd.!5e0!3m2!1sen!2sbd!4v1720298655459!5m2!1sen!2sbd"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        </div>
      </div>
      <HomeFooter />
    </div>
  );
};

export default Contact;
