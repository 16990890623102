import React from "react";
import "./Xavier.css";
import workImg1 from "../../images/xavier-setup-1.jpeg";
import workImg2 from "../../images/smart-home-house-technology-multimedia-5767f2742ff6f2c1e50810e9956a3c2b.jpg";
import workImg3 from "../../images/Xavier.png";
import VideosButton from "../VideoButton/VideosButton";

const Xavier = () => {
  return (
    <div className="d-flex flex-lg-row flex-md-column flex-sm-column flex-column  my-5 main-work border mx-1">
      <div className="workSystem-container">
        <div className="workInfo-container">
       
          <h3 className=" fontColor">Xavier - An affordable and accessible generic automation platform</h3>
          <h2 className="w-auto text-left">
            Seamless Security: The Perfect Integration for Your Smart Home
          </h2>
         
          <p className="w-auto">
            The product to adjust with the growing demand in industrial automation. It is a generic automation platform. It has the ability to collect data from simple data logging modules to complex machinery for analysis. It is the remedy toa ll industrial
            automation-related problems.
          </p>
        </div>
        <div className="infoWork-img d-flex flex-lg-row flex-md-row flex-sm-column flex-column">
          <img className="work-img" src={workImg3} alt="workImg" />
          <img className="work-img" src={workImg2} alt="workImg" />
          <img className="work-img" src={workImg1} alt="workImg" />
        </div>
        <div className="text-center bg-primary">
      
        </div>
      </div>

    
      <div className="workFeature-container px-lg-5 px-md-4 px-sm-3 px-2 py-5  w-auto">
        <h2>Features:</h2>
        <ul>
          <li>Load control</li>
          <li>
            GSM/WIFI/LoRA based wireless sensor network and customizable as per
            customer demand
          </li>
          <li>Interfaced with different types of sensors</li>
          <li>Real time parameter monitoring</li>
        </ul>
        <hr className="work-hr" />
        
        <div className="col">

          <button
            type="button"
            className="work-btn"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
          >
            Read More 
          </button>

          <div
            className="modal fade"
            id="exampleModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-scrollable text-black">
              <div className="modal-content">
                <div className="modal-header">
                  <h3 className="modal-title fw-bold custom-xavier-color" id="exampleModalLabel">
                    Xavier 
                  </h3>
                  <button
                    type="button"
                    className="btn-close "
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <h5 className="fw-bold custom-xavier-color">Application: </h5>
                  <ul>
                    <li>Integrated with various sorts of wireless sensors such as himidity, mosture, CO2 sensors for air, soil, water quality monitoring.</li>
                    <li>
                      Pressure sensor for CHiller Maintenance
                    </li>
                    <li>
                      Current Sensor for power monitoring
                    </li>
                    <li>
                      Counting Sensor for industrial production rate monitoring
                    </li>
                    <li>
                      Smooth and sealed surfaces for easy cleaning and
                      disinfection
                    </li>
                  </ul>

                  
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className=" custom-xavier-btn"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>






      </div>
    </div>
  );
};

export default Xavier;
