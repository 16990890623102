import React from "react";
import "./Carousel.css";
import img1 from "../../images/carousel/standard-quality-control-concept-m.jpg";
import img2 from "../../images/carousel/industrial-industry-automation-automatic.jpg";
import img3 from "../../images/carousel/s&m.png";
import img4 from "../../images/carousel/em.png";
import img5 from "../../images/carousel/eponics.jpg";
import img6 from "../../images/carousel/teamCover4.jpg";
import img7 from "../../images/carousel/image.jpg";

const Carousel = () => {
  return (
    <div
      id="carouselExampleIndicators"
      className="carousel slide"
      data-interval="1000"
      data-bs-ride="carousel"
    >
      <div className="carousel-inner">
        <div className="carousel-item active">
          <img
            src={img1}
            className="d-block w-100 img-style img-container"
            alt="..."
          />
          <div className="  d-md-block para-style1">
            <h1 className="fw-bold">
              AmarSource Ltd.
              <br /> Welcomes you!
            </h1>
            <p>
            A one-stop product and service provider for industrial automation.
            </p>
            <button className="btn-carousel"></button>
          </div>
        </div>
        <div className="carousel-item">
          <img
            src={img2}
            className="d-block w-100 img-style img-container"
            alt="..."
          />
          {/* <div className=" d-none d-md-block para-style1">
            <h1 className="fw-bold">
              AmarSource Ltd.
              <br /> Welcomes you!
            </h1>
            <p>
              AmarSource is an R&D company, set to work on various custom
              problems and challenges provided by individuals or other business
              and solve them utilizing locally available resources.
            </p>
            <button className="btn-carousel"></button>
          </div> */}
        </div>

        <div className="carousel-item">
          <img
            src={img3}
            className="d-flex  w-75  img-style img-container mx-auto"
            alt="..."
          />
          
        </div>
        <div className="carousel-item">
          <img
            src={img4}
            className="d-block w-50  mx-auto  img-style img-container "
            alt="..."
          />
          <div className=" d-none d-md-block para-style">
            {/* <h3 className="fw-bold">providing diversified customer needs and quick services whenever required, specializing in introducing electrical, mechanical and computer-based systems</h3> */}
          </div>
        </div>
        <div className="carousel-item">
          <img
            src={img5}
            className="d-block w-100  img-style img-container"
            alt="..."
          />
          <div className=" d-none d-md-block para-style">
            {/* <h3 className="fw-bold">Test</h3> */}
          </div>
        </div>
        <div className="carousel-item">
          <img
            src={img6}
            className="d-block w-100  img-style img-container"
            alt="..."
          />
          <div className=" d-none d-md-block para-style">
            {/* <h3 className="fw-bold">Test</h3> */}
          </div>
        </div>
        <div className="carousel-item">
          <img
            src={img7}
            className="d-block w-100  img-style img-container"
            alt="..."
          />
          <div className=" d-none d-md-block para-style">
            {/* <h3 className="fw-bold">Test</h3> */}
          </div>
        </div>

        <button
          class="carousel-control-prev  me-5 "
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next  ms-5 "
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
};

export default Carousel;
