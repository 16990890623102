import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./components/Home/Home";
import Contact from "./components/Contact/Contact";
import ScrollButton from "./components/ScrollButton/ScrollButton";
import Advisors from "./components/Team/Advisors";
import About from "./components/About/About";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ProSidebarProvider } from "react-pro-sidebar";
import { Provider } from "react-redux";
import store from "./redux/store";
import AdminDashboard from "./admin/AdminDashboard";
import AdminRoute from "./components/AdminRoute";
import UserRoute from "./components/UserRoute";
import CreatePost from "./admin/CreatePost";
import LogIn from "./pages/LogIn";
import Register from "./pages/Register";
import Layout from "./admin/global/Layout";
import EditPost from "./admin/EditPost";
import UserDashboard from "./user/UserDashboard";
import SinglePost from "./pages/SinglePost";
import BlogHome from "./pages/BlogHome";
import BlogPro from "./pages/BlogPro";
import SinglePro from "./pages/SinglePro";
import CreateProduct from "./admin/CreateProduct";
import EditProduct from "./admin/EditProduct";
import BuyContact from "./components/BuyContact";
import NotFound from "./components/NotFound/NotFound";
import Services from "./pages/Services/Services";
import TeamMembers from "./pages/TeamMembers";
import CreateMember from "./admin/CreateMember";
import EditMember from "./admin/EditMember";
import Timeline from "./pages/Timeline/Timeline";

//HOC
const AdminDashboardHOC = Layout(AdminDashboard);
const CreatePostHOC = Layout(CreatePost);
const CreateMemberHOC = Layout(CreateMember);
const CreateProductHOC = Layout(CreateProduct);
const EditPostHOC = Layout(EditPost);
const EditMemberHOC = Layout(EditMember);
const EditProductHOC = Layout(EditProduct);
const UserDashboardHOC = Layout(UserDashboard);

function App() {
  return (
    <>
      <ToastContainer />
      <Provider store={store}>
        <ProSidebarProvider>
          <BrowserRouter>
            <ScrollButton />
            <Routes>
              <Route path="/" element={<Home></Home>}></Route>
              <Route path="/home" element={<Home></Home>}></Route>
              <Route path="/contact" element={<Contact></Contact>}></Route>
              <Route path="/advisors" element={<Advisors />}></Route>
              <Route path="/about" element={<About />}></Route>
              <Route path="/services" element={<Services />}></Route>

              {/* blog  */}

              <Route path="/" element={<BlogHome />} />
              <Route path="/bloghome" element={<BlogHome />} />

              <Route path="/team" element={<TeamMembers />} />

              <Route path="/buycontact" element={<BuyContact />} />

              <Route path="/login" element={<LogIn />} />
              <Route path="/register" element={<Register />} />

              <Route path="/post/:id" element={<SinglePost />} />

              {/* product route */}
              <Route path="/products" element={<BlogPro />} />
              <Route path="/product/:id" element={<SinglePro />} />
              <Route path="timeline" element={<Timeline />} />


{/* adhoc // private routes */}
              <Route
                path="/admin/dashboard"
                element={
                  <AdminRoute>
                    <AdminDashboardHOC />
                  </AdminRoute>
                }
              />

              <Route
                path="/admin/post/create"
                element={
                  <AdminRoute>
                    <CreatePostHOC />
                  </AdminRoute>
                }
              />
              {/* team members */}
              <Route
                path="/admin/member/create"
                element={
                  <AdminRoute>
                    <CreateMemberHOC />
                  </AdminRoute>
                }
              />

              {/* product */}
              <Route
                path="/admin/product/create"
                element={
                  <AdminRoute>
                    <CreateProductHOC />
                  </AdminRoute>
                }
              />
              <Route
                path="/admin/post/edit/:id"
                element={
                  <AdminRoute>
                    <EditPostHOC />
                  </AdminRoute>
                }
              />
              {/* team member */}
              <Route
                path="/admin/member/edit/:id"
                element={
                  <AdminRoute>
                    <EditMemberHOC />
                  </AdminRoute>
                }
              />

              {/* product edit */}
              <Route
                path="/admin/product/edit/:id"
                element={
                  <AdminRoute>
                    <EditProductHOC />
                  </AdminRoute>
                }
              />

              <Route
                path="/user/dashboard"
                element={
                  <UserRoute>
                    <UserDashboardHOC />
                  </UserRoute>
                }
              />

              <Route path="*" element={<NotFound />}></Route>
              
            </Routes>
          </BrowserRouter>
        </ProSidebarProvider>
      </Provider>
    </>
  );
}

export default App;
