import React from "react";
import faqImg from "../../images/faq.jpg";
import "./Faq.css";
const Faq = () => {
  return (
    <div className="d-flex flex-lg-row flex-md-column flex-sm-column flex-column mb-3 border mx-1">

      <div className="w-100">
        <img className="faq-img w-sm-100 w-md-10 w-100" src={faqImg} alt="faq_img"/>
      </div>
      <div className="accordion container pt-3  w-100" >
        <h3 className="fontColor">FREQUENTLY ASKED QUESTIONS </h3>
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              // aria-expanded="true"
              aria-controls="collapseOne"
            >
              <h5 className="fw-bold">What problems do you solve?</h5>
            </button>
          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
            In last 10 years, we got the opportunity to work for various industries from Agriculture to Defense. We solved several technical problems for the first time in the history of our country. You can check the Product and Service sections. 
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
                <h5 className="fw-bold">How Xavier Works?</h5>
            </button>
          </h2>
          <div
            id="collapseTwo"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
            Xavier is a Wi-Fi Router shaped device with a powerful system architecture as the backgroud
            
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              <h5 className="fw-bold">I have a pond, can Xavier help to produce more fish?</h5>
            
            </button>
          </h2>
          <div
            id="collapseThree"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
            Yes. Xavier can detect and mitigates aquatic hypoxia risks with automation to increase productivity.
            </div>
          </div>
        </div>
      <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
               <h5 className="fw-bold">Can Xavier reduce electricity bill at my Home and Industry?</h5>
            </button>
          </h2>
          <div
            id="collapseFour"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
            Yes. Xavier also can optimize energy consumption by real-time remote sensing and control. 
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
