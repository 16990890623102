import {  faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import './Ideology.css';
import robot1 from "../../images/Timeline.png";
import { Link } from "react-router-dom";



const Ideology = () => {
  return (
    <section className="overflow-hidden mt-5 pt-5">
      <div>
        <div className="d-flex pt-5 row   overflow-hidden" >

          <div className="col-lg-6 col-md-12 col-sm-12 col-12  overflow-hidden mt-5 pt-4" >
            <img
              src={robot1}
              className="w-lg-100 w-md-100 w-sm-75 w-75 h-75 ms-5"
              alt="...."
            />
          </div>
          <div
            className="mt-5  pt-5 col-lg-6 col-md-12 col-sm-12
           col-12 ps-md-5 pb-md-5 ps-sm-5 ps- pb-sm-5 ps-5 pb-5 overflow-hidden pe-5"
           >
            <h2 className="fontColor">Timeline</h2>
            <h1>2014</h1>
            <p>
            AmarSource started in BUET campus with a team of 4 friends. The company was registered as a proprietorship company with name 'AmarSource Engineering'.
            </p>
            <div className="d-flex  flex-sm-column flex-md-row flex-lg-row flex-xs-column flex-column">
              <div>
                <h3>2021</h3>
                <p>We collected seed money from several angel investors and AmarSource was registered to RJSC as a Private limited company and raised seed fund from institutional investor - BD venture limited.</p>
              </div>
              <div>
                <h3>2023</h3>
                <p>On 22th October we launched our MVP - A complete automation platform targeting Aquaculture and Energy monitoring.</p>
              </div>

            </div>
            <Link to="/about">
            <button className="ideology-btn">LEARN MORE <FontAwesomeIcon className="rotation-prop" icon={faPlus} /></button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Ideology;
