import { FacebookProvider, CustomChat } from 'react-facebook';

const CustomMessaging = () => {
  return (
    <FacebookProvider appId="2906302596171779" chatSupport>
        <CustomChat pageId="2357463707610326" minimized="true"/>
      </FacebookProvider>  
  )
}

export default CustomMessaging;